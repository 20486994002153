<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        :label="$t('label.search')"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-switch
        :label="`AFAS Debiteur ${$t('label.empty')}`"
        class="mb-0 mt-2 py-0"
        hide-details
        v-model="afasDebtorIdIsNull"
      ></v-switch>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-switch
        :label="`Jira Epic ${$t('label.empty')}`"
        class="mb-0 mt-2 py-0"
        hide-details
        v-model="jiraEpicKeyIsNull"
      ></v-switch>
    </v-col>
    <v-spacer />
    <v-col align="right" cols="12" md="2" sm="6">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("label.clearFilters") }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="12">
      <v-data-table
        :footer-props="{
          'disable-pagination': $apollo.queries.tenants.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :headers="headers"
        :items="tenants.edges"
        :loading="$apollo.queries.tenants.loading"
        :options.sync="dataTableOptions"
        :server-items-length="tenants.totalCount"
        :show-select="me.isSuperuser ? 'show-select' : undefined"
        item-key="node.id"
        v-model="selectedItems"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="edit(item.node)" color="primary" icon>
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
    <TenantEditDialog
      :dialog.sync="editDialog"
      :object.sync="editedItem"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TenantEditDialog from "@/components/users/TenantEditDialog.vue";

export default {
  name: "tenants-list",
  components: {
    DeleteDialog,
    TenantEditDialog,
  },

  apollo: {
    tenants: {
      query: gql`
        query tenants(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
          $afasDebtorId_Isnull: Boolean
          $jiraEpicKey_Isnull: Boolean
        ) {
          tenants(
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
            orderBy: $orderBy
            afasDebtorId_Isnull: $afasDebtorId_Isnull
            jiraEpicKey_Isnull: $jiraEpicKey_Isnull
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                pk
                tenantName
                afasDebtorId
                allowedHosts
                azureAppObjectId
                azureClientId
                azureClientSecret
                azureServicePrincipalId
                jiraEpicKey
                theme {
                  id
                  themeName
                  primaryColor
                }
                tenantGroupSet {
                  edges {
                    node {
                      group {
                        id
                        name
                      }
                    }
                  }
                }
                maxUsers
                monthlyRefreshTokens
                customuserSet {
                  totalCount
                }
                reportSet {
                  totalCount
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
      pollInterval: 60000,
    },
  },

  data: function () {
    return {
      afasDebtorIdIsNull: false,
      jiraEpicKeyIsNull: false,
      tenants: {},
      deleteDialog: false,
      editDialog: false,
      editedItem: {},
      error: null,
      headers: [
        {
          text: "PK",
          value: "node.pk",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.tenantName",
          sortable: true,
        },
        {
          text: this.$tc("label.user", 2),
          value: "node.customuserSet.totalCount",
          sortable: false,
        },
        {
          text: this.$tc("label.report", 2),
          value: "node.reportSet.totalCount",
          sortable: false,
        },
        {
          text: `AFAS Debiteur ${this.$tc("label.id", 1)}`,
          value: "node.afasDebtorId",
          sortable: true,
        },
        {
          text: `Jira Epic Key`,
          value: "node.jiraEpicKey",
          sortable: true,
        },
        {
          text: `Max ${this.$tc("label.user", 2)}`,
          value: "node.maxUsers",
          sortable: true,
        },
        {
          text: "Allowed hosts",
          value: "node.allowedHosts",
          sortable: true,
        },
        {
          text: this.$tc("label.theme", 1),
          value: "node.theme.themeName",
          sortable: true,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      selectedItems: [],
    };
  },

  computed: {
    dataTableOptions: {
      get() {
        return this.$store.getters["tenantsList/getDataTableOptions"];
      },
      set(value) {
        this.$store.dispatch("tenantsList/setDataTableOptions", value);
      },
    },

    me() {
      return this.$store.state.user.me;
    },

    search: {
      get() {
        return this.$store.getters["tenantsList/getSearch"];
      },
      set(value) {
        this.$store.dispatch("tenantsList/setSearch", value);
      },
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    selectedItems() {
      this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
    },

    afasDebtorIdIsNull() {
      this.refresh();
    },

    jiraEpicKeyIsNull() {
      this.refresh();
    },
  },

  created() {
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    add() {
      this.editedItem = null;
      this.editDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    edit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    onChanged() {
      this.$apollo.queries.tenants.refresh();
    },

    onDeleteConfirmed() {
      var ids = [];
      this.selectedItems.forEach(function (item, index) {
        ids.push(item.node.id);
      });

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteTenants($input: DeleteTenantsInput!) {
              deleteTenants(input: $input) {
                tenants {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.tenants.refresh();

          this.deleteDialog = false;
          this.selectedItems = [];

          const payload = {
            color: "success",
            message: `Tenants successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.tenants.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.tenants.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      var variables = {
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
      };

      if (this.afasDebtorIdIsNull) {
        variables.afasDebtorId_Isnull = true;
      }

      if (this.jiraEpicKeyIsNull) {
        variables.jiraEpicKey_Isnull = true;
      }

      this.$apollo.queries.tenants.setVariables(variables);

      this.$apollo.queries.tenants.skip = false;
      this.$apollo.queries.tenants.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },
  },
};
</script>
